import React from "react";
import "./NoResultsFound.css";
export default function NoResultsFound({ image, content }) {
  return (
    <div className="no-results-container">
      <img src={image} alt="no pages found" />
      <p>{content}</p>
    </div>
  );
}
