import React, { useEffect, useState } from "react";
import fireApp from "../../config/firebase";
import "./FacebookLogin.css";

import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router";

import TextField from "@material-ui/core/TextField";

import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Alert from "@material-ui/lab/Alert/Alert";
import FBAuthFailedStatus from "../FBAuthFailedStatus/FBAuthFailedStatus";

import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import InfoIcon from "@material-ui/icons/Info";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FacebookIntegrationLoading from "../../components/FacebookIntegrationLoading/FacebookIntegrationLoading";
import FbPageLeadAdFormsListDrawer from "../../components/FbPageLeadAdFormsListDrawer/FbPageLeadAdFormsListDrawer";
import FbPagesListDrawer from "../../components/FbPagesListDrawer/FbPagesListDrawer";
import {
  CLOUD_FUNCTIONS_REGION,
  FACEBOOK_BUSINESS_LOGIN_CONFIG_ID,
  getAppSecretProof,
} from "../../utils/GlobalVariables";

export default function FacebookLogin(props) {
  const { search, state } = useLocation();
  const history = useHistory();

  const facebookIntegration = state?.facebookIntegration;

  // console.log("facebookIntegration", facebookIntegration);

  const urlScheme = new URLSearchParams(search);

  const userId = urlScheme.get("u") || urlScheme.get("userId");
  const projectId = urlScheme.get("p") || urlScheme.get("projectId");
  const projectName = urlScheme.get("pn") || urlScheme.get("projectName");

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [longLiveUserToken, setLongLiveUserToken] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedLeadAdForm, setSelectedLeadAdForm] = useState(null);

  const [openFbPagesDrawer, setOpenFbPagesDrawer] = useState(false);
  const [openFbPageLeadFormsDrawer, setOpenFbPageLeadFormsDrawer] =
    useState(false);

  const [statusCode, setStatusCode] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // Only works after `FB.init` is called
  const loginFacebook = () => {
    setIsAuthenticating(true);

    window.FB.login(
      function (response) {
        // console.log("Successfully logged in", response);
        // if (response.status === "connected") {
        //   // const access_token = response.authResponse.accessToken;
        // }
      },
      { config_id: FACEBOOK_BUSINESS_LOGIN_CONFIG_ID }
    );
  };

  useEffect(() => {
    loginFacebook();
  }, []);

  // useEffect(() => {
  //   const isExpired = isFacebookIntegrationExpired(facebookIntegration);

  //   if (facebookIntegration === null || isExpired) {
  //     loginFacebook();
  //   } else {
  //     setLongLiveUserToken(facebookIntegration.longLiveUserAccessToken);
  //     setIsAuthenticating(false);
  //   }
  // }, [facebookIntegration]);

  // listen to access token changes using facebook sdk
  useEffect(() => {
    window.FB.Event.subscribe("auth.authResponseChange", function (response) {
      if (response.status === "connected") {
        const accessToken = response.authResponse.accessToken;
        const fbAppUserId = response.authResponse.userID;

        getLongLiveUserAccessToken(fbAppUserId, accessToken);

        // Do something with the access token
        // console.log("Access token changed:", accessToken);
      }
    });
  }, []);

  const getLongLiveUserAccessToken = (fbAppUserId, access_token) => {
    window.FB.api(
      "/oauth/access_token",
      "get",
      {
        grant_type: "fb_exchange_token",
        client_id: process.env.REACT_APP_FB_APP_ID,
        client_secret: process.env.REACT_APP_FB_APP_SECRET,
        fb_exchange_token: access_token,
      },
      function (response) {
        if (!response || response.error) {
          showStatusBar(
            "error",
            "Failed to get access token from facebook. Try again."
          );
          setIsAuthenticating(false);
          return;
        }

        // console.log("Long live user access token", response);

        const long_live_access_token = response.access_token;

        updateFbIntegrationDetailsInCustomer(
          fbAppUserId,
          long_live_access_token
        );
      }
    );
  };

  // update facebook integration service details to customer
  const updateFbIntegrationDetailsInCustomer = (
    fbAppUserId,
    longLiveUserAccessToken
  ) => {
    const FB_WebhookFunctions_2A = fireApp
      .functions(CLOUD_FUNCTIONS_REGION)
      .httpsCallable("fb_leads_functions_group-FB_WebhookFunctions_2A");

    // facebook docs
    // https://developers.facebook.com/docs/facebook-login/guides/access-tokens/get-long-lived
    // addiing 60 days from current date as per facebook documentation

    const sixtyDaysInMilliseconds = 60 * 60 * 24 * 60 * 1000;
    const expiryAt = new Date().valueOf() + sixtyDaysInMilliseconds;

    FB_WebhookFunctions_2A({
      userId: userId,
      fbAppUserId: fbAppUserId,
      longLiveUserAccessToken: longLiveUserAccessToken,
      expiryAt: expiryAt,
    })
      .then((result) => {
        if (result.data !== null) {
          if (result.data.code === 200) {
            setLongLiveUserToken(longLiveUserAccessToken);
          }
          if (result.data.code === 400) {
            let errorMessage = result.data.message;
            showStatusBar("error", errorMessage);
          }
        }
      })
      .catch((err) => {
        console.log("updateFbIntegrationDetailsInCustomer", err);
        showStatusBar(
          "error",
          "Failed to update facebook integration details. Try again."
        );
      })
      .finally(() => {
        setIsAuthenticating(false);
      });
  };

  const subscribeAppToPage = async () => {
    setIsUpdating(true);
    const page_id = selectedPage.id;
    const page_access_token = selectedPage.access_token;
    // console.log("Subscribing page to app! " + page_id);

    const appsecret_proof = getAppSecretProof(page_access_token);

    window.FB.api(
      "/" + page_id + "/subscribed_apps",
      "post",
      {
        access_token: page_access_token,
        appsecret_proof: appsecret_proof,
        subscribed_fields: ["leadgen"],
      },
      function (response) {
        if (!response || response.error) {
          setIsUpdating(false);
          showStatusBar("error", "Failed connecting to fb page. Try again.");

          return;
        }
        // console.log("Successfully subscribed page", response);

        // updating in firestore db
        updateFbPageDetailsInProject(page_access_token);
      }
    );
  };

  const updateFbPageDetailsInProject = async () => {
    const FB_WebhookFunctions_2 = fireApp
      .functions(CLOUD_FUNCTIONS_REGION)
      .httpsCallable("fb_leads_functions_group-FB_WebhookFunctions_2");

    let pageData = {
      pageId: selectedPage.id,
      pageName: selectedPage.name,
      categoryList: selectedPage.category_list,
      pageToken: selectedPage.access_token,
      leadAdFormId: selectedLeadAdForm?.id ?? null,
      leadAdFormName: selectedLeadAdForm?.name ?? null,
    };

    FB_WebhookFunctions_2({
      userId: userId,
      projectId: projectId,
      pageData: pageData,
    })
      .then(async (result) => {
        if (result.data !== null) {
          if (result.data.code === 200) {
            // redirect it to success page once uploaded
            history.push("/fb-integration/success");
          }
          if (result.data.code === 400) {
            let errorMessage = result.data.message;
            showStatusBar("error", errorMessage);
          }
        }
      })
      .catch((err) => {
        showStatusBar("error", "Failed to connect facebook page. Try again.");
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const showStatusBar = (status, message) => {
    setStatusCode(status);
    setModalMessage(message);
    setOpenSnackBar(true);
  };

  const onPageSelected = (page) => {
    setOpenFbPagesDrawer(false);
    setSelectedPage(page);
  };

  const onLeadAdFormSelected = (page) => {
    setOpenFbPageLeadFormsDrawer(false);
    setSelectedLeadAdForm(page);
  };

  return (
    <>
      <div className="fb-integration-container">
        {isAuthenticating ? (
          <FacebookIntegrationLoading />
        ) : longLiveUserToken !== null ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              pointerEvents: isUpdating ? "none" : "auto",
            }}
          >
            <div className="fb-integration-header">
              <h2>
                Select the facebook page that you wish to connect leads with{" "}
                LeedsApp project <span>{projectName}</span>
              </h2>
              {/* <p>(ie.) Page that you plan to run lead ads campaign.</p> */}
            </div>

            <div style={{ width: "100%", height: "50px" }}></div>

            <div>
              <TextField
                label="Facebook page"
                variant="outlined"
                fullWidth
                tabIndex={-1}
                onClick={(e) => setOpenFbPagesDrawer(true)}
                onChange={(e) => {}}
                value={selectedPage?.name ?? "Tap to select page"}
                style={{ marginBottom: "40px" }}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <span>
                      <MenuBookIcon
                        style={{
                          color: "grey",
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      />
                    </span>
                  ),
                }}
              />
              {selectedPage != null && (
                <TextField
                  label="Lead/Ad form (Optional)"
                  variant="outlined"
                  fullWidth
                  onClick={(e) => setOpenFbPageLeadFormsDrawer(true)}
                  onChange={(e) => {}}
                  value={
                    selectedLeadAdForm?.name ??
                    "Tap to select lead form (Optional)"
                  }
                  style={{ marginBottom: "40px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <span>
                        <ContactPhoneIcon
                          style={{
                            color: "grey",
                            fontSize: 20,
                            marginRight: 10,
                          }}
                        />
                      </span>
                    ),
                  }}
                />
              )}

              <div className="fb-important-notes">
                <h4 className="flex-container-start">
                  <InfoIcon style={{ fontSize: "17px", marginRight: "5px" }} />{" "}
                  Important notes
                </h4>
                <p>
                  1) If you have any doubts regarding which page / lead Ad form
                  need to be selected, Kindly contact your digital
                  marketer/agency and ask them which page they are going to run
                  leads ads.
                </p>
                <p>
                  2) Please select the page that is related to your project. If
                  failed, leads will get mixed up with multiple projects.
                </p>
                <p>
                  3) Leave the Lead/Ad Form Field Empty to collect leads from
                  all Ad Forms associated with the Page.
                </p>
                <p>
                  4) If your page isn't visible here, it indicates that you
                  currently lack administrative (ADMIN) access to it
                </p>
              </div>
            </div>

            <div style={{ width: "100%", height: "100px" }}></div>

            <div className="fb-connect-button-container">
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  width: "100%",
                  fontSize: "17px",
                  borderRadius: "10px",
                  height: "50px",
                  backgroundColor:
                    selectedPage == null
                      ? "#DDDDDD"
                      : isUpdating
                      ? "#79abf5"
                      : "#0165FE",
                }}
                onClick={subscribeAppToPage}
                endIcon={isUpdating ? null : <ArrowForwardIcon />}
                disabled={selectedPage == null}
              >
                {isUpdating ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Connect"
                )}
              </Button>
            </div>
          </div>
        ) : (
          <FBAuthFailedStatus loginFacebook={loginFacebook} />
        )}
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnackBar}
        autoHideDuration={3000}
        TransitionProps={{ unmountOnExit: true }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert onClose={(e) => setOpenSnackBar(false)} severity={statusCode}>
          {modalMessage}
        </Alert>
      </Snackbar>

      {openFbPagesDrawer && (
        <FbPagesListDrawer
          openFbPagesDrawer={openFbPagesDrawer}
          setOpenFbPagesDrawer={setOpenFbPagesDrawer}
          longLiveUserToken={longLiveUserToken}
          selectedPage={selectedPage}
          projectName={projectName}
          showStatusBar={showStatusBar}
          onPageSelected={onPageSelected}
        />
      )}

      {openFbPageLeadFormsDrawer && (
        <FbPageLeadAdFormsListDrawer
          openFbPageLeadFormsDrawer={openFbPageLeadFormsDrawer}
          setOpenFbPageLeadFormsDrawer={setOpenFbPageLeadFormsDrawer}
          selectedPage={selectedPage}
          selectedLeadAdForm={selectedLeadAdForm}
          showStatusBar={showStatusBar}
          onLeadAdFormSelected={onLeadAdFormSelected}
        />
      )}
    </>
  );
}
