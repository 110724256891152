import { Button, Divider } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ShareIcon from "@material-ui/icons/Share";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import DemoPayload from "../../components/DemoPayload";
import { WEBSITE_INTERGATION_API_BASE_URL } from "../../utils/GlobalVariables";
import "./WebsiteIntegration.css";

export default function WebsiteIntegration() {
  const { search } = useLocation();

  const urlScheme = new URLSearchParams(search);

  const [projectName, setProjectName] = useState(null);
  const [endPointUrl, setEndPointUrl] = useState("");

  const userId = urlScheme.get("u") || urlScheme.get("userId");
  const projectId = urlScheme.get("p") || urlScheme.get("projectId");
  const name = urlScheme.get("pn") || urlScheme.get("projectName");

  useEffect(() => {
    const url = `${WEBSITE_INTERGATION_API_BASE_URL}/?u=${userId}&p=${projectId}`;
    setEndPointUrl(url);
    setProjectName(name);

    return () => {};
  }, []);

  return (
    <div className="website-integration-demo-container">
      <div className="website-integration-header">
        <h2>Integration with Website / Landing page</h2>

        <p>
          Follow the steps to connect your Website / Landing page to LeedsApp
          project <span>{projectName}</span>
        </p>
      </div>
      <h3>Technical Documentation</h3>
      <Divider style={{ marginTop: "7px" }} />
      <h4>Introduction:</h4>
      <p>
        This request should be made while user submitting the form in your
        website / landing page. <br />
        <br />
        This request will send data to LeedsApp servers that is responsible for
        handling Website integration with LeedsApp projects. The request
        requires specific information to be sent in the body of the request.
      </p>
      <h4>Request Method:</h4>
      <p>
        The request type is <span>POST</span> request.
      </p>
      <h4>Request URL:</h4>
      <p>The endpoint for this POST request is</p>
      <div className="sample-request">
        <code>{endPointUrl}</code>
      </div>
      <h4>Request Headers:</h4>
      <p>The headers for this POST request should include the following:</p>
      <div className="sample-request">
        <code>Content-Type: application/json</code>
      </div>
      <h4>Request Body:</h4>
      <p>
        The body of the request must contain the following fields in JSON
        format:
      </p>
      <br />
      <p>The following details will be needed to make a request.</p>
      <ul>
        <li>name* (String) [Mandatory]</li>
        <li>phoneNumber* (String) [Mandatory]</li>
        <li>email (String) [Optional]</li>
        <li>city (String) [Optional]</li>
        <li>custom_field (String) [Optional]</li>
      </ul>
      <p>
        Additionally, If you have custom fields in the form, it can be added to
        the payload as a key - value pair. To know more, see sample request
        below.
      </p>
      <br />
      <br />

      <p>Example Request:</p>
      <div className="sample-request">
        <pre>
          <code>
            POST /website-integration-demo/?u={userId}
            &p={projectId} <br />
            HTTP/1.1 Host: {WEBSITE_INTERGATION_API_BASE_URL}
            <br />
            Content-Type: application/json
            <br />
            <br />
            <b className="website_payload">
              <DemoPayload />
            </b>
            <br />
          </code>
        </pre>
      </div>

      <h4>Response:</h4>
      <p>
        The server will respond with an appropriate status code{" "}
        <span>(e.g., 200 OK, 400 Bad Request, 500 Internal Server Error)</span>{" "}
        and a message confirming that the data was received and processed. The
        exact response format will depend on the server implementation.
      </p>
      <div className="website-integration-notes">
        <h4>Important Notes:</h4>
        <p>
          Do not edit the query pararms <span>"u"</span> and <span>"p"</span> in
          the request url.
        </p>
      </div>
      <div style={{ height: "50px" }}></div>
    </div>
  );
}

const ShareDeveloperMessage = ({ shareIntegrationDemo }) => {
  return (
    <>
      <div className="info-container">
        <b className="flex-container-start">
          <InfoIcon style={{ marginRight: "5px", fontSize: "16px" }} /> Note
        </b>
        <p>
          Share this link to your website developer and ask them to do the
          needful.
        </p>
      </div>

      <Button
        variant="contained"
        color="primary"
        style={{
          backgroundColor: "#1778F2",
          width: "100%",
          height: "50px",
          borderRadius: "10px",
          textTransform: "capitalize",
          fontSize: "17px",
          marginTop: "10px",
        }}
        startIcon={<ShareIcon style={{ fontSize: "20px" }} />}
        onClick={shareIntegrationDemo}
      >
        Share
      </Button>
    </>
  );
};
