import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import DemoPayload from "../../components/DemoPayload";
import ShareDeveloperMessage from "../../components/ShareDeveloperMessage/ShareDeveloperMessage";
import {
  PROJECT_INTEGRATION_BASE_URL,
  SUPPORT_EMAIL,
  WEBHOOOK_INTERGATION_API_BASE_URL,
  cityOptions,
  emailOptions,
  mobileNumberOptions,
  personNameOptions,
} from "../../utils/GlobalVariables";
import "./WebhookIntegration.css";

const fieldAlternatviesSplitList = [0, 15, 30, 45, 60, 75];

export default function WebhookIntegration() {
  const { search, pathname } = useLocation();

  const urlScheme = new URLSearchParams(search);

  const [projectName, setProjectName] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState(null);
  const [shareUrl, setShareUrl] = useState(null);

  useEffect(() => {
    const userId = urlScheme.get("u") || urlScheme.get("userId");
    const projectId = urlScheme.get("p") || urlScheme.get("projectId");
    const name = urlScheme.get("pn") || urlScheme.get("projectName");

    // pathName contains forward and backward slashes here
    const endPointUrl = `${WEBHOOOK_INTERGATION_API_BASE_URL}/?u=${userId}&p=${projectId}`;

    const shareUrl = `${PROJECT_INTEGRATION_BASE_URL}/webhook-integration/?u=${userId}&p=${projectId}`;

    setWebhookUrl(endPointUrl);
    setShareUrl(shareUrl);
    setProjectName(name);

    return () => {};
  }, []);

  const shareIntegrationDemo = () => {
    const shareText = `*LeedsApp Integrations (Webhook)*\n\nFollow the steps from the link to integrate your preferred marketing automation tool with LeedsApp project.\n\n${shareUrl}`;

    window.open(
      `whatsapp://send?text=${encodeURIComponent(shareText)}`,
      "_self"
    );
  };

  return (
    <div className="website-integration-demo-container">
      <div className="website-integration-header">
        <h2>Webhook Integration Documentation</h2>

        <p>
          Follow the steps to connect your favorite marketing automation tool to
          LeedsApp project <span>{projectName}</span>
        </p>
      </div>

      <div style={{ padding: "20px 0px" }}>
        <ShareDeveloperMessage shareIntegrationDemo={shareIntegrationDemo} />
      </div>
      <h4>Introduction:</h4>
      <p>
        Welcome to the documentation for integrating with our webhook service.
        This document provides instructions for connecting your marketing
        automation solution to our webhook endpoint, which expects the following
        parameters:
      </p>
      <ul>
        <li>name* (String) [Mandatory]</li>
        <li>phoneNumber* (String) [Mandatory]</li>
        <li>email (String) [Optional]</li>
        <li>city (String) [Optional]</li>
        <li>custom_field (String) [Optional]</li>
      </ul>

      <p>
        Additionally, If you have custom fields in the form, it can be added to
        the payload as a key - value pair. For more details check for{" "}
        <a href="#how-to-use-webhook">payload format</a>
      </p>

      <h4>Webhook URL:</h4>
      <div className="sample-request">
        <code>{webhookUrl}</code>
      </div>

      <h4>Table of Contents</h4>
      <div className="webook-table-contents">
        <ol>
          <li>
            <a href="#webhook-overview">Webhook Overview</a>
          </li>
          <li>
            <a href="#how-to-use-webhook">How to Use the Webhook</a>
            <ul>
              <li>
                <a href="#authentication">Authentication</a>
              </li>
              <li>
                <a href="#payload-format">Payload Format</a>
              </li>
              <li>
                <a href="#payload-alternatives">Payload Field Alternatives</a>
                <ul>
                  <li>
                    <a href="#name-field">Name Field</a>
                  </li>
                  <li>
                    <a href="#phoneNumber-field">Phone Number Field</a>
                  </li>
                  <li>
                    <a href="#email-field">Email Field</a>
                  </li>
                  <li>
                    <a href="#city-field">City Field</a>
                  </li>
                  <li>
                    <a href="#custom-fields">Custom Fields</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href="#supported-marketing-automation-solutions">
              Supported Marketing Automation Solutions
            </a>
            <ul>
              <li>
                <a href="#zapier-integration">Zapier Integration</a>
              </li>
              <li>
                <a href="#pabbly-integration">Pabbly Integration</a>
              </li>
              <li>
                <a href="#integromat-integration">Integromat Integration</a>
              </li>
              <li>
                <a href="#hubspot-integration">HubSpot Integration</a>
              </li>
              <li>
                <a href="#marketo-integration">Marketo Integration</a>
              </li>
              <li>
                <a href="#custom-integration">Custom Integration</a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#troubleshooting">Troubleshooting</a>
          </li>
          <li>
            <a href="#contact-support">Contact Support</a>
          </li>
        </ol>
      </div>
      <h4 id="webhook-overview">Webhook Overview</h4>
      <p>
        A webhook is a way to send real-time data from one application to
        another when specific events or triggers occur. In our case, this
        webhook is designed to receive lead data or other relevant information
        from your marketing automation solution, including the parameters
        mentioned above, and process it accordingly.
      </p>
      <h3 id="how-to-use-webhook">How to Use the Webhook</h3>
      <h4 id="authentication">Authentication</h4>
      <p>
        Authentication for the webhook is achieved by including the{" "}
        <b>userId</b> and <b>projectId</b> as query parameters in the webhook
        URL. Make sure to replace the placeholders with your actual user and
        project IDs.
      </p>

      <h4 id="payload-format">Payload Format</h4>
      <p>
        The webhook expects data in JSON format with the following structure:
      </p>
      <div className="sample-request">
        <DemoPayload showCustomFields={true} />
      </div>
      <p>
        The payload should include the <b>name</b>, <b>phoneNumber</b>,{" "}
        <b>email</b>, <b>city</b> parameters with their respective values.
      </p>
      <h3 id="payload-alternatives">Payload Field Alternatives</h3>
      <br />
      <p>
        Our webhook is flexible and can accept payloads tailored to your
        requirements. You can verify whether the following fields are compatible
        with your preferred integration platform.
      </p>
      <h4 id="name-field">Name Field</h4>
      <div className="payload-alternatives-container">
        {fieldAlternatviesSplitList.map((split) => {
          return (
            <ul key={split}>
              {personNameOptions.slice(split, split + 10).map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          );
        })}
      </div>

      <h4 id="phoneNumber-field">Phone Number Field</h4>
      <div className="payload-alternatives-container">
        {fieldAlternatviesSplitList.map((split) => {
          return (
            <ul key={split}>
              {mobileNumberOptions.slice(split, split + 10).map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          );
        })}
      </div>
      <h4 id="email-field">Email Field</h4>
      <div className="payload-alternatives-container">
        {fieldAlternatviesSplitList.map((split) => {
          return (
            <ul key={split}>
              {emailOptions.slice(split, split + 10).map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          );
        })}
      </div>
      <h4 id="city-field">City Field</h4>
      <div className="payload-alternatives-container">
        {fieldAlternatviesSplitList.map((split) => {
          return (
            <ul key={split}>
              {cityOptions.slice(split, split + 10).map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          );
        })}
      </div>

      <div className="payload-fields-note flex-container-start">
        <InfoIcon
          style={{ fontSize: "25px", marginRight: "20px", color: "#F4CE14" }}
        />{" "}
        <p>
          Note: If the above fields do not meet your requirements, please design
          your integration platform to align with our webhook.
        </p>
      </div>

      <h3 id="supported-marketing-automation-solutions">
        Supported Marketing Automation Solutions
      </h3>
      <br />
      <p>
        We provide instructions for integrating with popular marketing
        automation solutions using the parameters specified.
      </p>
      <h4 id="zapier-integration">Zapier Integration</h4>
      <div className="integration-instructions">
        <ol>
          <li>Log in to your Zapier account.</li>
          <li>Create a new Zap.</li>
          <li>Choose your marketing automation solution as the trigger app.</li>
          <li>
            For the action app, search for and select "Webhooks by Zapier."
          </li>
          <li>Choose the "Custom Request" action event.</li>
          <li>
            Configure the custom request with the webhook URL and payload data.
          </li>
          <li>Test and activate your Zap.</li>
        </ol>
      </div>
      <h4 id="pabbly-integration">Pabbly Integration</h4>
      <div className="integration-instructions">
        <ol>
          <li>Log in to your Pabbly account.</li>
          <li>
            Create a new workflow or automation depending on your use case.
          </li>
          <li>Add a new action step to your workflow.</li>
          <li>Search for "Webhooks" and select the webhook action.</li>
          <li>
            Configure the webhook action with the webhook URL and payload data
            that includes <b>name</b>, <b>phoneNumber</b>, <b>email</b>, and{" "}
            <b>city</b>.
          </li>
          <li>Save and activate your workflow or automation.</li>
        </ol>
      </div>
      <h4 id="integromat-integration">Integromat Integration</h4>
      <div className="integration-instructions">
        <ol>
          <li>Log in to your Integromat account.</li>
          <li>Create a new scenario.</li>
          <li>Add your marketing automation solution as the trigger module.</li>
          <li>Configure the trigger according to your requirements.</li>
          <li>Add the "HTTP {">"} Make a Request" module.</li>
          <li>Configure the module with the webhook URL and payload data.</li>
          <li>Test and activate your scenario.</li>
        </ol>
      </div>
      <h4 id="hubspot-integration">HubSpot Integration</h4>
      <div className="integration-instructions">
        <ol>
          <li>Log in to your HubSpot account.</li>
          <li>
            Go to "Automations" or "Workflows," depending on your HubSpot
            version.
          </li>
          <li>Create a new workflow or automation.</li>
          <li>Add a new trigger or action step.</li>
          <li>Search for "Webhooks" and select the webhook action.</li>
          <li>
            Configure the webhook action with the webhook URL and payload data.
          </li>
          <li>Activate your workflow or automation.</li>
        </ol>
      </div>
      <h4 id="marketo-integration">Marketo Integration</h4>
      <div className="integration-instructions">
        <ol>
          <li>Log in to your Marketo account.</li>
          <li>
            Navigate to "Admin" and select "LaunchPoint" in the Integration
            section.
          </li>
          <li>Click "New" to create a new service.</li>
          <li>Choose "Webhooks" as the service type.</li>
          <li>Configure the webhook with the webhook URL and payload data.</li>
          <li>Save and activate your webhook service.</li>
        </ol>
      </div>
      <h4 id="custom-integration">Custom Integration</h4>
      <p>
        For custom integrations or if your marketing automation solution is not
        listed here, you can use the webhook URL provided in the introduction
        section. Ensure that your solution can make HTTP POST requests with JSON
        payloads to the webhook URL.
      </p>
      <h4 id="troubleshooting">Troubleshooting</h4>
      <p>
        If you encounter any issues during the integration process or need
        assistance, please refer to the troubleshooting section in the
        documentation of your specific marketing automation solution. You can
        also contact our support team for further assistance.
      </p>
      <h4 id="contact-support">Contact Support</h4>
      <p>
        If you have any questions or require assistance with your integration,
        please contact our support team at{" "}
        <a href={`mailto: ${SUPPORT_EMAIL}`} target="_blank">
          {SUPPORT_EMAIL}
        </a>
      </p>
      <div className="website-integration-notes">
        <h4>Important Notes:</h4>
        <p>
          Do not edit the query pararms <span>"u"</span> and <span>"p"</span> in
          the request url.
        </p>
      </div>
      <div style={{ padding: "20px 0px" }}>
        <ShareDeveloperMessage shareIntegrationDemo={shareIntegrationDemo} />
      </div>
      <div style={{ height: "30px" }}></div>
    </div>
  );
}
