import React from "react";
import { useHistory, useLocation } from "react-router";
import { projectIntegrationsList } from "../../utils/GlobalVariables";
import "./ProjectIntegrations.css";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import WebIcon from "@material-ui/icons/Web";

import LeedsAppImageGrey from "../../images/marker_logo_grey.png";

export default function ProjectIntegrations() {
  const history = useHistory();
  const { search } = useLocation();

  const onButtonPressed = (integrationId) => {
    const urlScheme = new URLSearchParams(search);

    const userId = urlScheme.get("u") || urlScheme.get("userId");
    const projectId = urlScheme.get("p") || urlScheme.get("projectId");
    const projectName = urlScheme.get("pn") || urlScheme.get("projectName");

    if (integrationId === "facebook") {
      history.push(
        `/fb-integration-demo/?u=${userId}&p=${projectId}&pn=${projectName}`
      );
    }
    if (integrationId === "website") {
      history.push(
        `/website-integration-demo/?u=${userId}&p=${projectId}&pn=${projectName}`
      );
    }
    if (integrationId === "webhook") {
      history.push(
        `/webhook-integration/?u=${userId}&p=${projectId}&pn=${projectName}`
      );
    }
  };

  const getIconOnIntegration = (integrationId) => {
    if (integrationId === "facebook") {
      return <FacebookIcon style={{ color: "#1778F2", fontSize: "45px" }} />;
    }
    if (integrationId === "website") {
      return <WebIcon style={{ color: "#2196F3", fontSize: "45px" }} />;
    }
    if (integrationId === "webhook") {
      return <LanguageIcon style={{ color: "#FFC107", fontSize: "45px" }} />;
    }
  };

  return (
    <div className="project-integrations-container">
      <List>
        {projectIntegrationsList.map((item) => {
          return (
            <ListItem
              key={item.id}
              button
              onClick={(e) => {
                onButtonPressed(item.id);
              }}
              style={{ padding: "20px 10px" }}
            >
              <ListItemAvatar>{getIconOnIntegration(item.id)}</ListItemAvatar>
              <div style={{ marginRight: "50px", marginLeft: "10px" }}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>

              <ListItemSecondaryAction>
                <ArrowForwardIosIcon
                  style={{ color: "grey", fontSize: "16px" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>

      <div style={{ height: "50px" }}></div>

      <label>
        We are continuously working on more integrations and will be available
        in future updates.
      </label>
      <img src={LeedsAppImageGrey} alt="leedsapp" />
      <div style={{ height: "50px" }}></div>
    </div>
  );
}
