import React, { Fragment, useEffect, useState } from "react";
import "./FbPageLeadAdFormsListDrawer.css";
// -------------  material components ----------------
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import Radio from "@material-ui/core/Radio/Radio";

// ------------ material icons ---------------
import ClearIcon from "@material-ui/icons/Clear";
import ListAltIcon from "@material-ui/icons/ListAlt";
// -------------  custom components --------------
import NoResultsFound from "../NoResultsFound/NoResultsFound";

// ------------  images ----------------
import { ListItemIcon } from "@material-ui/core";
import NoResultsImage from "../../images/noresults1.png";
import { getAppSecretProof } from "../../utils/GlobalVariables";
import PageLoadingWidget from "../PageLoadingWidget/PageLoadingWidget";
import SearchBar from "../SearchBar/SearchBar";

let nextCursorUrl;
export default function FbPageLeadAdFormsListDrawer({
  openFbPageLeadFormsDrawer,
  setOpenFbPageLeadFormsDrawer,
  selectedPage,
  selectedLeadAdForm,
  showStatusBar,
  onLeadAdFormSelected,
}) {
  const [isPagesFetching, setIsPagesFetching] = useState(true);
  const [mainLeadAdFormsList, setMainLeadAdFormsList] = useState([]);
  const [leadAdFormsList, setLeadAdFormsList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const page_access_token = selectedPage.access_token;

  const appsecret_proof = getAppSecretProof(page_access_token);

  const closeDrawer = () => {
    setOpenFbPageLeadFormsDrawer(false);
  };

  const getLeadForms = () => {
    setIsPagesFetching(true);
    setLeadAdFormsList([]);

    window.FB.api(
      `/${selectedPage.id}/leadgen_forms?limit=200`,
      "get",
      {
        access_token: page_access_token,
        appsecret_proof: appsecret_proof,
      },
      function (response) {
        if (!response || response.error) {
          showStatusBar(
            "error",
            "Failed to retrieve lead ad forms. Try again."
          );
          setIsPagesFetching(false);

          return;
        }

        // console.log("Successfully retrieved lead forms", response);

        nextCursorUrl = response.paging && response.paging.next;

        let pages = response.data;

        setLeadAdFormsList(pages);
        setMainLeadAdFormsList(pages);
        setIsPagesFetching(false);
      }
    );
  };

  const getMoreLeadAdForms = async (event) => {
    // var node = event.target;

    // const bottom =
    //   parseInt(Math.round(node.scrollHeight - node.scrollTop), 10) ===
    //     node.innerHeight ||
    //   parseInt(Math.round(node.scrollHeight - node.scrollTop), 10) - 1 ===
    //     node.innerHeight; // some times an offset of 1 is always there after reached the scroll bottom. thats y this condition

    const { scrollTop, scrollHeight, clientHeight } = event.target;

    const reachedBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (
      reachedBottom &&
      nextCursorUrl &&
      !moreItemsLoading &&
      searchText.trim().length === 0
    ) {
      setMoreItemsLoading(true);

      window.FB.api(
        nextCursorUrl,
        "get",
        {
          access_token: page_access_token,
          appsecret_proof: appsecret_proof,
        },
        function (response) {
          if (!response || response.error) {
            showStatusBar(
              "error",
              "Failed to retrieve lead ad forms. Try again."
            );
            setMoreItemsLoading(false);

            return;
          }

          nextCursorUrl = response.paging && response.paging.next;

          let pages = response.data;

          let updatedList = [...mainLeadAdFormsList, ...pages];

          setLeadAdFormsList(updatedList);
          setMainLeadAdFormsList(updatedList);
          setMoreItemsLoading(false);
        }
      );
    }
  };

  const searchLeadAdForms = (val) => {
    setSearchText(val);

    if (val.trim().length > 0) {
      let searchList = mainLeadAdFormsList.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );

      setLeadAdFormsList(searchList);
    } else {
      setLeadAdFormsList(mainLeadAdFormsList);
    }
  };

  useEffect(() => {
    getLeadForms();

    return () => {};
  }, []);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openFbPageLeadFormsDrawer}
      onClose={closeDrawer}
      PaperProps={{
        style: { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" },
      }}
    >
      <div className="fb-pages-list-drawer" onScroll={getMoreLeadAdForms}>
        <ListSubheader style={{ padding: 0, backgroundColor: "white" }}>
          <div className="fb-pages-list-header">
            <div
              className="flex-container-space"
              style={{ marginBottom: "15px", alignItems: "flex-start" }}
            >
              <div style={{ marginRight: 20 }}>
                <h2>Select the Lead/Ad form</h2>
                <p>
                  Note: Don't select Lead/Ad Form Field if you wish to collect
                  Leads from all Ad Forms associated with the Page.
                </p>
              </div>
              <IconButton
                onClick={closeDrawer}
                size="small"
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <ClearIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </IconButton>
            </div>

            <SearchBar
              value={searchText}
              placeHolder="Search by lead form name"
              onChangeHandler={searchLeadAdForms}
              clearInputValue={(e) => {
                setSearchText("");
                setLeadAdFormsList(mainLeadAdFormsList);
              }}
            />
          </div>
        </ListSubheader>

        <List
          style={{
            width: "100%",
            padding: "10px 20px",
          }}
        >
          {isPagesFetching ? (
            [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return <PageLoadingWidget key={item} />;
            })
          ) : leadAdFormsList.length > 0 ? (
            leadAdFormsList.map((item) => {
              return (
                <Fragment key={item.id}>
                  <ListItem
                    style={{
                      padding: "10px 0px",
                      width: "100%",
                    }}
                    onClick={(e) => {
                      onLeadAdFormSelected(item);
                    }}
                    button
                  >
                    <ListItemIcon>
                      <div className="page-avatar flex-container">
                        <ListAltIcon />
                      </div>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      secondary={
                        <span
                          style={{
                            color: item.status === "ACTIVE" ? "green" : "red",
                            backgroundColor:
                              item.status === "ACTIVE" ? "#f0fff0" : "#fff2f2",
                          }}
                          id="lead-form-status"
                        >
                          {item.status === "ACTIVE" ? "Active" : "Inactive"}
                        </span>
                      }
                      primaryTypographyProps={{
                        style: { paddingRight: "50px" },
                      }}
                      style={{
                        maxWidth: "fit-content",
                        wordBreak: "break-word",
                      }}
                    />
                    <ListItemSecondaryAction>
                      <Radio
                        edge="end"
                        checked={selectedPage?.id === item.id}
                        onChange={(e) => {
                          onLeadAdFormSelected(item);
                        }}
                        color={"primary"}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {/* <Divider style={{ margin: "10px 0px" }} /> */}
                </Fragment>
              );
            })
          ) : (
            <div style={{ marginTop: "50px" }}>
              <NoResultsFound
                image={NoResultsImage}
                content="No Lead/Ad forms created"
              />
            </div>
          )}
        </List>

        {moreItemsLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <PageLoadingWidget />
          </div>
        )}
      </div>
    </SwipeableDrawer>
  );
}
