import React, { Fragment, useEffect, useState } from "react";
import "./FbPagesListDrawer.css";
// -------------  material components ----------------
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Skeleton from "@material-ui/lab/Skeleton";

import Radio from "@material-ui/core/Radio/Radio";

// ------------ material icons ---------------
import ClearIcon from "@material-ui/icons/Clear";

// -------------  custom components --------------
import NoResultsFound from "../../components/NoResultsFound/NoResultsFound";

// ------------  images ----------------
import { ListItemIcon, ListItemText } from "@material-ui/core";
import NoResultsImage from "../../images/noresults1.png";
import { getAppSecretProof } from "../../utils/GlobalVariables";
import SearchBar from "../SearchBar/SearchBar";

let nextCursorUrl;
export default function FbPagesListDrawer({
  openFbPagesDrawer,
  setOpenFbPagesDrawer,
  longLiveUserToken,
  projectName,
  showStatusBar,
  onPageSelected,
  selectedPage,
}) {
  const [isPagesFetching, setIsPagesFetching] = useState(true);
  const [mainFbPagesList, setMainFbPagesList] = useState([]);
  const [fbPagesList, setFbPagesList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const appsecret_proof = getAppSecretProof(longLiveUserToken);

  const closeDrawer = () => {
    setOpenFbPagesDrawer(false);
  };

  const getFbPages = () => {
    setIsPagesFetching(true);
    setFbPagesList([]);

    window.FB.api(
      "/me/accounts?limit=200",
      "get",
      {
        access_token: longLiveUserToken,
        appsecret_proof: appsecret_proof,
      },
      function (response) {
        if (!response || response.error) {
          showStatusBar(
            "error",
            "Failed to retrieve facebook pages. Try again."
          );
          setIsPagesFetching(false);

          return;
        }

        //   console.log("Successfully retrieved pages", response);

        nextCursorUrl = response.paging && response.paging.next;

        const pages = response.data;

        setFbPagesList(pages);
        setMainFbPagesList(pages);
        setIsPagesFetching(false);
      }
    );
  };

  const getMoreFbPages = async (event) => {
    // var node = event.target;
    // const bottom =
    //   parseInt(Math.round(node.scrollHeight - node.scrollTop), 10) ===
    //     node.innerHeight ||
    //   parseInt(Math.round(node.scrollHeight - node.scrollTop), 10) - 1 ===
    //     node.innerHeight; // some times an offset of 1 is always there after reached the scroll bottom. thats y this condition

    const { scrollTop, scrollHeight, clientHeight } = event.target;

    const reachedBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (
      reachedBottom &&
      nextCursorUrl &&
      !moreItemsLoading &&
      searchText.trim().length === 0
    ) {
      setMoreItemsLoading(true);

      window.FB.api(
        nextCursorUrl,
        "get",
        {
          access_token: longLiveUserToken,
          appsecret_proof: appsecret_proof,
        },
        function (response) {
          if (!response || response.error) {
            showStatusBar(
              "error",
              "Failed to retrieve facebook pages. Try again."
            );
            setMoreItemsLoading(false);

            return;
          }

          nextCursorUrl = response.paging && response.paging.next;

          const pages = response.data;

          let updatedList = [...mainFbPagesList, ...pages];

          setFbPagesList(updatedList);
          setMainFbPagesList(updatedList);
          setMoreItemsLoading(false);
        }
      );
    }
  };

  const searchFbPages = (val) => {
    setSearchText(val);

    // let searchQuery = val.trim().toLowerCase();

    // if (searchQuery.length > 0) {
    //   window.FB.api(
    //     "/search",
    //     "get",
    //     {
    //       type: "page",
    //       q: searchQuery,
    //       access_token: longLiveUserToken,
    //       appsecret_proof: appsecret_proof,
    //     },
    //     function (response) {
    //       console.log(response);
    //       if (response && !response.error) {
    //         const pages = response.data || [];
    //         setFbPagesList(pages);
    //       } else {
    //         showStatusBar("error", "Error while searching for pages");
    //       }
    //     }
    //   );
    // } else {
    //   getFbPages();
    // }

    if (val.trim().length > 0) {
      let searchList = mainFbPagesList.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );

      setFbPagesList(searchList);
    } else {
      setFbPagesList(mainFbPagesList);
    }
  };

  useEffect(() => {
    getFbPages();

    return () => {};
  }, []);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openFbPagesDrawer}
      onClose={closeDrawer}
      PaperProps={{
        style: { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" },
      }}
    >
      <div className="fb-pages-list-drawer" onScroll={getMoreFbPages}>
        <ListSubheader style={{ padding: 0, backgroundColor: "white" }}>
          <div className="fb-pages-list-header">
            <div
              className="flex-container-space"
              style={{ marginBottom: "15px", alignItems: "flex-start" }}
            >
              <div style={{ marginRight: 20 }}>
                <h2>Select the page</h2>
                <p>
                  Note: Please select the page which is relevant to project{" "}
                  <span>"{projectName}"</span>
                </p>
              </div>
              <IconButton
                onClick={closeDrawer}
                size="small"
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <ClearIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </IconButton>
            </div>

            <SearchBar
              value={searchText}
              placeHolder="Search by page name"
              onChangeHandler={searchFbPages}
              clearInputValue={(e) => {
                setSearchText("");
                setFbPagesList(mainFbPagesList);
              }}
            />
          </div>
        </ListSubheader>

        <List
          style={{
            width: "100%",
            padding: "10px 20px",
          }}
        >
          {isPagesFetching ? (
            [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return <PageLoadingWidget key={item} />;
            })
          ) : fbPagesList.length > 0 ? (
            fbPagesList.map((item) => {
              return (
                <Fragment key={item.id}>
                  <ListItem
                    style={{
                      padding: "10px 0px",
                      width: "100%",
                    }}
                    onClick={(e) => {
                      onPageSelected(item);
                    }}
                    button
                  >
                    <ListItemIcon>
                      <div className="page-avatar flex-container">
                        {item.name.slice(0, 1)}
                      </div>
                    </ListItemIcon>

                    <ListItemText
                      primary={item.name}
                      secondary={item.category}
                      primaryTypographyProps={{
                        style: { paddingRight: "50px" },
                      }}
                      style={{
                        maxWidth: "fit-content",
                        wordBreak: "break-word",
                      }}
                    />

                    <ListItemSecondaryAction>
                      <Radio
                        edge="end"
                        checked={selectedPage?.id === item.id}
                        onChange={(e) => {
                          onPageSelected(item);
                        }}
                        color={"primary"}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {/* <Divider style={{ margin: "10px 0px" }} /> */}
                </Fragment>
              );
            })
          ) : (
            <div style={{ marginTop: "50px" }}>
              <NoResultsFound image={NoResultsImage} content="No pages found" />
            </div>
          )}
        </List>

        {moreItemsLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <PageLoadingWidget />
          </div>
        )}
      </div>
    </SwipeableDrawer>
  );
}

const PageLoadingWidget = () => {
  return (
    <div className="page-loading-item flex-container-start">
      <Skeleton
        variant="circle"
        width={50}
        height={50}
        style={{ marginRight: "10px" }}
      />
      <div>
        <Skeleton
          variant="rect"
          height={25}
          width="60vw"
          style={{ borderRadius: "5px" }}
        />
        <Skeleton
          variant="rect"
          height={15}
          style={{
            borderRadius: "5px",
            marginTop: "10px",
            width: "70%",
          }}
        />
      </div>
    </div>
  );
};
