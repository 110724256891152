import React from "react";
import "./NotFound.css";
export default function NotFound() {
  return (
    <div className="not-found-page">
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}
