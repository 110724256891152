import { HmacSHA256, enc } from "crypto-js";
import LeedsAppLogoImage from "../images/leedsapp-logo-red-black-text.png";

//const MARKER_WEB_BASE_URL = "https://192.168.1.3:3000";
const PROJECT_INTEGRATION_BASE_URL = "https://integrations.leedsapp.com";

const WEBSITE_INTERGATION_API_BASE_URL =
  "https://asia-south1-civil-marker-dev.cloudfunctions.net/website_leads_functions_group-WebsiteFunctions_1";

const WEBHOOOK_INTERGATION_API_BASE_URL =
  "https://asia-south1-civil-marker-dev.cloudfunctions.net/webhook_leads_functions_group-WebhookFunctions_1";

const CLOUD_FUNCTIONS_REGION = "asia-south1";

const FACEBOOK_BUSINESS_LOGIN_CONFIG_ID = "608978771144848";

const SUPPORT_EMAIL = "support@leedsapp.com";

const faqsList = [
  {
    id: "FAQ1",
    title: "What is the Leads Store app, and how can it benefit my business?",
    content:
      "The Leads Store app is a powerful lead management tool designed to empower businesses with efficient lead handling, follow-up capabilities, and accelerated deal closures. By optimizing lead management, the app helps your business streamline operations, seize opportunities, and drive substantial growth.",
  },
  {
    id: "FAQ2",
    title: "Is Leads Store suitable for businesses of all sizes?",
    content:
      "Absolutely! The Leads Store app caters to businesses of all sizes, from small startups to well-established enterprises. Regardless of your company's scale, Leads Store is designed to adapt to your lead management needs and fuel your success.",
  },
  {
    id: "FAQ3",
    title: "How does Leads Store capture and organize leads?",
    content:
      "Leads Store simplifies lead capture by allowing you to gather leads from multiple sources, such as website forms, social media channels, and more. The app intuitively organizes these leads into a centralized repository for easy access and efficient lead tracking.",
  },
  {
    id: "FAQ4",
    title: "Can I set reminders for timely follow-ups with leads?",
    content:
      "Yes, indeed! Leads Store offers customizable reminders and notifications, ensuring you and your team never miss a critical follow-up. Stay on top of interactions, build stronger relationships, and enhance your chances of closing deals.",
  },
  {
    id: "FAQ5",
    title: "Does Leads Store provide data insights on lead performance?",
    content:
      "Absolutely! Leads Store provides comprehensive data insights and analytics, enabling you to gain valuable knowledge about lead performance. Analyze key metrics, identify trends, and optimize lead conversion strategies to maximize your success.",
  },
  {
    id: "FAQ6",
    title: "Is Leads Store a secure platform for managing sensitive lead data?",
    content:
      "Rest assured, your data security is our top priority. Leads Store employs robust encryption and industry-standard security measures to protect your valuable lead information. Your data is safe and accessible only to authorized users.",
  },
  {
    id: "FAQ7",
    title: "Does Leads Store promote collaboration among team members?",
    content:
      "Absolutely! Collaboration is at the core of our vision for Leads Store. While the collaborative team environment is not available in the current version, we have exciting plans for upcoming updates. Our development team is actively working on integrating a collaborative feature that will allow you to delegate leads, communicate effortlessly within the app, and track progress on shared leads.",
  },
  {
    id: "FAQ8",
    title: "What sets Leads Store apart from other lead management apps?",
    content:
      "Leads Store is distinguished by its user-friendly interface, data-driven insights, and customizable workflows. We prioritize a seamless user experience, enabling you to focus on closing deals and driving business growth.",
  },
  {
    id: "FAQ9",
    title: "How do I get started with Leads Store?",
    content:
      "Getting started is simple! Download Leads Store from the App Store, create an account, and start exploring the app's features. Our intuitive onboarding process will guide you through setting up your lead management system efficiently.",
  },
];

const personNameOptions = [
  "name",
  "full_name",
  "user_name",
  "first_name",
  "last_name",
  "fname",
  "lname",
  "given_name",
  "family_name",
  "n",
  "full_name_input",
  "customer_name",
  "client_name",
  "contact_name",
  "person_name",
  "member_name",
  "employee_name",
  "participant_name",
  "guest_name",
  "attendee_name",
  "author_name",
  "name_input",
  "full_name_field",
  "name_value",
  "name_text",
  "person",
  "title",
  "profile_name",
  "alias",
  "display_name",
  "app_name",
  "account_holder",
  "owner_name",
  "recipient_name",
  "subject_name",
  "user_fullname",
  "individual_name",
  "entity_name",
  "entity_title",
  "title_name",
  "main_contact",
  "main_user",
  "name_entry",
  "identity",
  "handle",
  "tag_name",
  "participant_fullname",
  "visitor_name",
  "customer_fullname",
  "leader_name",
  "delegate_name",
];

const mobileNumberOptions = [
  "phoneNumber",
  "mobileNumber",
  "mobile",
  "mobile_number",
  "cell",
  "cell_number",
  "phone",
  "phone_number",
  "contact_number",
  "tel",
  "telephone",
  "contact_phone",
  "mob_number",
  "mobile_phone",
  "mobile_contact",
  "phone_mobile",
  "phone_cell",
  "contact_tel",
  "tel_number",
  "cell_phone",
  "mobile_tel",
  "contact_cell",
  "phone_contact",
  "cell_contact",
  "contact_mob",
  "mobile_contact_number",
  "cell_contact_number",
  "phone_contact_number",
  "mobile_tel_number",
  "cell_tel_number",
  "phone_mob_number",
  "contact_phone_number",
  "mobile_num",
  "cell_num",
  "phone_num",
  "contact_num",
  "tel_num",
  "telephone_num",
  "mob_num",
  "cell_phone_num",
  "contact_tel_num",
  "mob_phone",
  "mob_phone_number",
  "mob_tel",
  "mob_tel_number",
  "cell_tel",
  "cell_tel_number",
];

const emailOptions = [
  "email",
  "email_address",
  "email_id",
  "e_mail",
  "e_address",
  "electronic_mail",
  "email_contact",
  "email_input",
  "email_field",
  "email_value",
  "email_text",
  "user_email",
  "contact_email",
  "recipient_email",
  "email_address_input",
  "email_id_input",
  "email_value_input",
  "user_email_input",
  "contact_email_input",
  "recipient_email_input",
  "email_input_field",
  "email_entry",
  "email_textbox",
  "email_form",
  "email_box",
  "email_input_box",
  "email_address_box",
  "email_field_box",
  "user_email_box",
  "contact_email_box",
  "recipient_email_box",
  "email_box_input",
  "email_address_box_input",
  "email_field_box_input",
  "user_email_box_input",
  "contact_email_box_input",
  "recipient_email_box_input",
];

const cityOptions = [
  "city",
  "city_name",
  "town",
  "town_name",
  "locality",
  "district",
  "district_name",
  "state",
  "state_name",
  "urban_area",
  "metropolis",
  "city_input",
  "city_field",
  "city_value",
  "city_text",
  "location",
  "municipality",
  "city_name_input",
  "town_name_input",
  "locality_input",
  "urban_area_input",
  "metropolis_input",
  "location_input",
  "municipality_input",
  "city_input_field",
  "city_entry",
  "city_textbox",
  "city_form",
  "city_box",
  "city_input_box",
  "city_name_box",
  "town_name_box",
  "locality_box",
  "urban_area_box",
  "metropolis_box",
  "location_box",
  "municipality_box",
  "city_box_input",
  "city_name_box_input",
  "town_name_box_input",
  "locality_box_input",
  "urban_area_box_input",
  "metropolis_box_input",
  "location_box_input",
  "municipality_box_input",
];

const projectIntegrationsList = [
  {
    id: "facebook",
    title: "Facebook / Instagram",
    description:
      "Receive new leads from Facebook & Instagram ads directly in your LeedsApp",
  },
  {
    id: "website",
    title: "Website / Landing page",
    description:
      "Receive new leads from your product website / landing page directly in your LeedsApp",
  },
  {
    id: "webhook",
    title: "Webhook",
    description:
      "Connect our webhook to any leading marketing automation solutions like Zapier, Pabbly etc., to directly get your leads in LeedsApp",
  },
];

const getAppSecretProof = (access_token) => {
  return HmacSHA256(access_token, process.env.REACT_APP_FB_APP_SECRET).toString(
    enc.Hex
  );
};

export {
  CLOUD_FUNCTIONS_REGION,
  FACEBOOK_BUSINESS_LOGIN_CONFIG_ID,
  LeedsAppLogoImage,
  PROJECT_INTEGRATION_BASE_URL,
  SUPPORT_EMAIL,
  WEBHOOOK_INTERGATION_API_BASE_URL,
  WEBSITE_INTERGATION_API_BASE_URL,
  cityOptions,
  emailOptions,
  faqsList,
  getAppSecretProof,
  mobileNumberOptions,
  personNameOptions,
  projectIntegrationsList,
};
