import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import ShareIcon from "@material-ui/icons/Share";
import Button from "@material-ui/core/Button";

export default function ShareDeveloperMessage({ shareIntegrationDemo }) {
  return (
    <>
      <div className="info-container">
        <b className="flex-container-start">
          <InfoIcon style={{ marginRight: "5px", fontSize: "16px" }} /> Note
        </b>
        <p>
          Share this link to your website developer / digital marketing agency /
          whoever developed your website/landing site and ask them to do the
          needful.
        </p>
      </div>

      <Button
        variant="contained"
        color="primary"
        style={{
          backgroundColor: "#1778F2",
          width: "100%",
          height: "50px",
          borderRadius: "10px",
          textTransform: "capitalize",
          fontSize: "17px",
          marginTop: "10px",
        }}
        startIcon={<ShareIcon style={{ fontSize: "20px" }} />}
        onClick={shareIntegrationDemo}
      >
        Share
      </Button>
    </>
  );
}
