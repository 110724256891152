import React from "react";
import "./PageLoadingWidget.css";

import Skeleton from "@material-ui/lab/Skeleton";

export default function PageLoadingWidget() {
  return (
    <div className="page-loading-item flex-container-start">
      <Skeleton
        variant="circle"
        width={50}
        height={50}
        style={{ marginRight: "10px" }}
      />
      <div>
        <Skeleton
          variant="rect"
          height={25}
          width="60vw"
          style={{ borderRadius: "5px" }}
        />
        <Skeleton
          variant="rect"
          height={15}
          style={{
            borderRadius: "5px",
            marginTop: "10px",
            width: "70%",
          }}
        />
      </div>
    </div>
  );
}
