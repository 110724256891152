import React from "react";

export default function DemoPayload() {
  return (
    <pre>
      <code>
        {`{\n  "name": "John Doe",\n  "phoneNumber": "+919876543210",\n  "email": "support@leedsapp.com",\n  "city": Chennai",\n  "customField1": "value1",\n  "customField2": "value2",\n  "customField3": "value3",\n}`}
      </code>
    </pre>
  );
}
