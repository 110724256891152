import React from "react";
import "./FBAuthFailedStatus.css";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import ReplayIcon from "@material-ui/icons/Replay";
import { LeedsAppLogoImage } from "../../utils/GlobalVariables";

export default function FBAuthFailedStatus({ loginFacebook }) {
  return (
    <div className="integration-success-message">
      <div
        className="flex-container"
        style={{ flexDirection: "column", marginTop: "100px" }}
      >
        <CancelIcon style={{ fontSize: "90px", color: "#DF2E38" }} />
        <p>Failed to login facebook. Try again.</p>

        <Button
          variant="outlined"
          color="default"
          style={{
            textTransform: "capitalize",
            fontSize: "14px",
            marginTop: "25px",
          }}
          onClick={loginFacebook}
          startIcon={<ReplayIcon />}
        >
          Try again
        </Button>
      </div>
    </div>
  );
}
