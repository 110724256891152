import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./FbIntegrationDemo.css";

import FacebookIcon from "@material-ui/icons/Facebook";
import CircularProgressBar from "../../components/CircularProgressBar/CircularProgressBar";

// -------------- images ----------------
import FBIntegrateImage1 from "../../images/fb-integration-1.png";
import FBIntegrateImage2 from "../../images/fb-integration-2.png";
import FBIntegrateImage3 from "../../images/fb-integration-3.png";
import FBIntegrateImage4 from "../../images/fb-integration-4.png";

const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

export default function FbIntegrationDemo() {
  const history = useHistory();
  const { search } = useLocation();

  const urlScheme = new URLSearchParams(search);

  const userId = urlScheme.get("u") || urlScheme.get("userId");
  const projectId = urlScheme.get("p") || urlScheme.get("projectId");
  const projectName = urlScheme.get("pn") || urlScheme.get("projectName");

  const [isLoading, setIsLoading] = useState(true);
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [facebookIntegration, setFacebookIntegration] = useState(null);

  const onFbIntegationStart = () => {
    history.push(
      `/fb-integration?u=${userId}&p=${projectId}&pn=${projectName}`,
      {
        facebookIntegration: facebookIntegration,
      }
    );
  };

  // useEffect(() => {
  //   const checkCustomerAlreadyConnected = () => {
  //     setIsCustomerLoading(true);

  //     const CustomerFunctions_1A = fireApp
  //       .functions(CLOUD_FUNCTIONS_REGION)
  //       .httpsCallable("customers_functions_group-CustomerFunctions_1A");

  //     CustomerFunctions_1A({
  //       customerId: userId,
  //     })
  //       .then((result) => {
  //         if (result.data !== null) {
  //           if (result.data.code === 200) {
  //             const facebookIntegration =
  //               result.data.customerData?.integrationServices?.FACEBOOK ?? null;

  //             setFacebookIntegration(facebookIntegration);
  //           }

  //           if (result.data.code === 400) {
  //             let errorMessage = result.data.message;
  //             // console.log(errorMessage);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         setIsCustomerLoading(false);
  //       });
  //   };

  //   checkCustomerAlreadyConnected();
  // }, []);

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // useEffect(() => {
  //   if (facebookIntegration !== null && !isCustomerLoading) {
  //     // checking if the expiration time is passed
  //     const isExpired = isFacebookIntegrationExpired(facebookIntegration);

  //     if (!isExpired) {
  //       onFbIntegationStart();
  //       return;
  //     }
  //   }
  // }, [facebookIntegration, isCustomerLoading]);

  return isLoading ? (
    <CircularProgressBar size={30} />
  ) : (
    <div className="fb-integration-demo-container">
      <h4>
        Follow the steps while integrating facebook page with LeedsApp project
      </h4>

      <br />

      <p>
        1) Login in your facebook using your email & password. If you
        successfully logged in, you will be redirected to below page, press{" "}
        <span>Continue</span>.
      </p>
      <img className="demo-image" src={FBIntegrateImage1} />
      <br />
      <br />
      <p>
        2) Choose <span>Opt in to all current and future Pages</span> option and
        then press <span>Continue</span>.
      </p>
      <img className="demo-image" src={FBIntegrateImage2} />
      <br />
      <br />
      <p>
        3) Review the following permissions that are needed to get leads in your
        LeedsApp account. Press <span>Save</span>.
      </p>
      <img className="demo-image" src={FBIntegrateImage3} />
      <br />
      <br />

      <p>
        4) If everything goes well, you will see the message{" "}
        <span>"You has benefit connected to LeedsApp Integrations"</span>.
      </p>
      <img className="demo-image" src={FBIntegrateImage4} />
      <br />

      <div className="fb-login-button">
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "#1778F2",
            width: "100%",
            height: "50px",
            borderRadius: "10px",
            textTransform: "capitalize",
            fontSize: "16px",
          }}
          startIcon={<FacebookIcon style={{ fontSize: "25px" }} />}
          onClick={onFbIntegationStart}
        >
          Login with facebook
        </Button>
      </div>

      <div style={{ height: "30px" }}></div>
    </div>
  );
}
