import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import "./FacebookIntegrationLoading.css";
export default function FacebookIntegrationLoading() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Skeleton
        variant="rect"
        height={30}
        width="50%"
        style={{ borderRadius: "5px", marginBottom: "30px" }}
      />

      <Skeleton
        variant="rect"
        height={20}
        width="80%"
        style={{ borderRadius: "5px", margin: "15px 0px" }}
      />
      <Skeleton
        variant="rect"
        height={20}
        width="80%"
        style={{ borderRadius: "5px", margin: "15px 0px" }}
      />
      <div style={{ height: "50px" }}></div>

      <Skeleton
        variant="rect"
        height="50px"
        width="100%"
        style={{ borderRadius: "5px" }}
      />

      <div style={{ height: "30px" }}></div>

      <Skeleton
        variant="rect"
        height="50px"
        width="100%"
        style={{ borderRadius: "5px" }}
      />
      <div style={{ height: "50px" }}></div>

      <Skeleton
        variant="rect"
        height={20}
        width="40%"
        style={{ borderRadius: "5px", marginBottom: "20px" }}
      />

      {[1, 2, 3].map((item) => {
        return (
          <Skeleton
            key={item}
            variant="rect"
            height={15}
            width="80%"
            style={{ borderRadius: "5px", margin: "15px 0px" }}
          />
        );
      })}
    </div>
  );
}
