import React from "react";
import "./CircularProgressBar.css";

import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export default function CircularProgressBar({ size, color }) {
  return (
    <div className="flex-container" style={{ marginTop: "50px" }}>
      <CircularProgress
        variant="indeterminate"
        size={size}
        style={{ color: color !== undefined ? color : "" }}
      />
    </div>
  );
}
