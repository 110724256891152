import Divider from "@material-ui/core/Divider";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./WebsiteIntegrationDemo.css";

import ShareDeveloperMessage from "../../components/ShareDeveloperMessage/ShareDeveloperMessage";
import { PROJECT_INTEGRATION_BASE_URL } from "../../utils/GlobalVariables";

export default function WebsiteIntegrationDemo() {
  const { search } = useLocation();

  const urlScheme = new URLSearchParams(search);

  const [projectName, setProjectName] = useState(null);
  const [shareUrl, setShareUrl] = useState("");

  const shareIntegrationDemo = () => {
    const shareText = `*LeedsApp Integrations (Website)*\n\nFollow the steps from the link to integrate your website with LeedsApp project.\n\n${shareUrl}`;

    window.open(
      `whatsapp://send?text=${encodeURIComponent(shareText)}`,
      "_self"
    );
  };

  useEffect(() => {
    const userId = urlScheme.get("u") || urlScheme.get("userId");
    const projectId = urlScheme.get("p") || urlScheme.get("projectId");
    const name = urlScheme.get("pn") || urlScheme.get("projectName");

    const url = `${PROJECT_INTEGRATION_BASE_URL}/website-integration/?u=${userId}&p=${projectId}`;

    setShareUrl(url);
    setProjectName(name);

    return () => {};
  }, []);

  return (
    <div className="website-integration-demo-container">
      <div
        className="website-integration-header"
        style={{ marginBottom: "30px" }}
      >
        <h2>Connect with Website / Landing page</h2>

        <p>
          Follow the procedure to connect your Website / Landing page to
          LeedsApp project <span>{projectName}</span>
        </p>
        <Divider style={{ marginTop: "20px" }} />
      </div>

      <br />

      <p>
        You can get leads directly from <span>submit form</span> placed in your
        product/service website or landing page.
      </p>
      <br />

      <p>
        Integration with website/landing page needs some{" "}
        <span>technical support</span> from your website developer. <br />
        <br />
        Therefore, share the following link to your website developer to connect
        the desired website/landing page in your <span>LeedsApp project</span>.
      </p>
      <div className="code-container">
        <code>{shareUrl}</code>
      </div>

      <div>
        <ShareDeveloperMessage shareIntegrationDemo={shareIntegrationDemo} />
      </div>

      <div
        className="website-integration-notes"
        style={{ margin: "50px 0px 20px 0px" }}
      >
        <h4>Important Notes:</h4>
        <p>
          1) Integrate your website / landing page that is related to your
          LeedsApp project{" "}
          <span style={{ textTransform: "capitalize" }}>({projectName})</span>,
          Otherwise leads will get mixed up in multiple projects.
        </p>
        <p>
          2) You can also connect multiple websites / landing page to a single
          project. However, sites must be related to that LeedsApp project.
        </p>
      </div>
      <div style={{ height: "50px" }}></div>
    </div>
  );
}
