import { useHistory, useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import "./App.css";

import FBIntegrationSuccess from "./screens/FBIntegrationSuccess/FBIntegrationSuccess";
import FacebookLogin from "./screens/FacebookLogin/FacebookLogin";
import FbIntegrationDemo from "./screens/FbIntegrationDemo/FbIntegrationDemo";
import NotFound from "./screens/NotFound/NotFound";
import ProjectIntegrations from "./screens/ProjectIntegrations/ProjectIntegrations";
import WebhookIntegration from "./screens/WebhookIntegration/WebhookIntegration";
import WebsiteIntegration from "./screens/WebsiteIntegration/WebsiteIntegration";
import WebsiteIntegrationDemo from "./screens/WebsiteIntegrationDemo/WebsiteIntegrationDemo";
import { LeedsAppLogoImage } from "./utils/GlobalVariables";

function App() {
  return (
    <div className="app-container">
      <Header />
      <div className="main-body">
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              return <ProjectIntegrations />;
            }}
          />
          {/* -------------  facebook integration --------------------- */}
          <Route
            exact
            path="/fb-integration-demo"
            render={(props) => {
              return <FbIntegrationDemo {...props} />;
            }}
          />
          <Route
            exact
            path="/fb-integration"
            render={(props) => {
              return <FacebookLogin {...props} />;
            }}
          />
          <Route
            exact
            path="/fb-integration/success"
            render={(props) => {
              return <FBIntegrationSuccess {...props} />;
            }}
          />
          {/* -------------  website integration --------------------- */}
          <Route
            exact
            path="/website-integration-demo"
            render={(props) => {
              return <WebsiteIntegrationDemo {...props} />;
            }}
          />

          <Route
            exact
            path="/website-integration"
            render={(props) => {
              return <WebsiteIntegration {...props} />;
            }}
          />

          {/* -------------  webhook integration --------------------- */}
          <Route
            exact
            path="/webhook-integration"
            render={(props) => {
              return <WebhookIntegration {...props} />;
            }}
          />

          {/* ------------- leads store documents ------------------ */}
          {/* <Route
        exact
        path="/leadstore/terms-of-service"
        render={(props) => {
          return <TermsOfService {...props} />;
        }}
      />

      <Route
        exact
        path="/leadstore/privacy-policy"
        render={(props) => {
          return <PrivacyPolicy {...props} />;
        }}
      />

      <Route
        exact
        path="/leadstore/FAQs"
        render={(props) => {
          return <FAQs {...props} />;
        }}
      /> */}

          <Route
            render={(props) => {
              return <NotFound {...props} />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
}

export default App;

const Header = () => {
  const history = useHistory();
  const { search } = useLocation();

  const gotoHomePage = () => {
    const urlScheme = new URLSearchParams(search);
    const userId = urlScheme.get("u") || urlScheme.get("userId");
    const projectId = urlScheme.get("p") || urlScheme.get("projectId");
    const projectName = urlScheme.get("pn") || urlScheme.get("projectName");

    history.push(`/?u=${userId}&p=${projectId}&pn=${projectName}`);
  };

  return (
    <div className="app-header">
      <div className="leedsapp-integration-logo" onClick={gotoHomePage}>
        <img
          onClick={(e) => {
            gotoHomePage();
          }}
          src={LeedsAppLogoImage}
          alt="leedsapp-logo"
        />
        <p>Integrations</p>
      </div>
    </div>
  );
};
