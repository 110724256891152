import React, { useEffect } from "react";
import "./FBIntegrationSuccess.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default function FBIntegrationSuccess() {
  useEffect(() => {
    //document.getElementById("facebook-jssdk")?.remove();

    setTimeout(() => {
      closeWindow();
    }, 3000);

    return () => {};
  }, []);

  const closeWindow = () => {
    // window.FB.logout(function (response) {
    window.close();

    // });
  };

  return (
    <div className="integration-success-message">
      <div
        className="flex-container"
        style={{ flexDirection: "column", marginTop: "100px" }}
      >
        <CheckCircleIcon style={{ fontSize: "90px", color: "green" }} />
        <p>Yah! Page has connected successfully.</p>

        <span className="info-text">
          Close the window if not closed automatically
        </span>
      </div>
    </div>
  );
}
