import React from "react";
import "./SearchBar.css";

//---------------- material icons -------------------
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";

export default function SearchBar({
  value,
  onChangeHandler,
  clearInputValue,
  placeHolder,
}) {
  return (
    <div className="search-bar">
      <SearchIcon
        fontSize="small"
        style={{ fontSize: "17px", marginRight: "5px" }}
      />
      <input
        type="text"
        name="search-appointment"
        value={value}
        autoComplete="off"
        placeholder={placeHolder === undefined ? "Search here..." : placeHolder}
        onChange={(e) => onChangeHandler(e.target.value)}
      />
      {value.length > 0 && (
        <Tooltip title="Clear Text">
          <ClearIcon
            fontSize="small"
            style={{ cursor: "pointer", fontSize: "18px" }}
            onClick={clearInputValue}
          />
        </Tooltip>
      )}
    </div>
  );
}
